export function organizerResources() {
  return [
    {
      id: "NewOrganizerStartHere",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "New Organizer? Start Here",
      thumbnail: "/img/resources/organizer/suaw-stock-image-table-tent-coffee.png",
      route: "/resources/organizer/new-organizer-start-here",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Guide covering core principles, responsibilities, and practical steps for new organizers to begin hosting events."
    },
    {
      id: "OrganizerHandbook",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Organizer Handbook",
      thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
      route: "/resources/organizer/organizer-handbook",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Comprehensive guide covering all aspects of organizing writing events, from basic principles to advanced community building."
    },
    {
      id: "OrganizerFaq",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Organizer FAQ",
      thumbnail: "/img/resources/organizer/suaw-stock-image-socialize.png",
      route: "/resources/organizer/organizer-faq",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Answers to common questions about organizing events, managing communities, and handling various organizer responsibilities."
    },
    {
      id: "BeforeYourVideoCall",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Before Your Video Call",
      thumbnail: "/img/resources/organizer/suaw-stock-image-table-tent-writers.png",
      route: "/resources/organizer/before-your-video-call",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Guide and checklist for online writing sessions, covering technical setup, hosting tips, and best practices."
    },
    {
      id: "ListingYourInPersonEvent",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Listing Your In-Person Event",
      thumbnail: "/img/resources/organizer/suaw-stock-image-bookmark.png",
      route: "/resources/organizer/listing-your-in-person-event",
      category: "Organizer",
      subcategory: "Hosting Events",
      preview: "Guide for creating compelling event listings that communicate details and attract writers to in-person sessions."
    },
    {
      id: "HostingYourInPersonEvent",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Hosting Your In-Person Event",
      thumbnail: "/img/resources/organizer/suaw-stock-image-writers-2.png",
      route: "/resources/organizer/hosting-your-in-person-event",
      category: "Organizer",
      subcategory: "Hosting Events",
      preview: "Guide for organizing successful in-person events, from venue selection to facilitation and community building."
    },
    {
      id: "InPersonEventChecklist",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "In-Person Event Checklist",
      thumbnail: "/img/resources/organizer/suaw-stock-image-writer-notepad-2.png",
      route: "/resources/organizer/in-person-event-checklist",
      category: "Organizer",
      subcategory: "Hosting Events",
      preview: "Checklist for venue preparation, materials, timing, and participant management at in-person writing events."
    },
    // hidden until content is updated
    // {
    //   id: "ListingYourOnlineEvent",
    //   authorId: "SuawTeam",
    //   // author: "Shut Up & Write! Team",
    //   // posted: "",
    //   heading: "Listing Your Online Event",
    //   thumbnail: "/img/resources/organizer/suaw-stock-image-writers-1.png",
    //   route: "/resources/organizer/listing-your-online-event",
    //   category: "Organizer",
    //   subcategory: "Hosting Events",
    //   preview: "Tips and strategies for crafting online event listings that attract participants and maximize session attendance."
    // },
    {
      id: "HostingYourOnlineEvent",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Hosting Your Online Event",
      thumbnail: "/img/resources/organizer/suaw-stock-image-writer-computer.png",
      route: "/resources/organizer/hosting-your-online-event",
      category: "Organizer",
      subcategory: "Hosting Events",
      preview: "Guide to hosting virtual sessions, covering setup, participant engagement, and common troubleshooting solutions."
    },
    {
      id: "OnlineEventChecklist",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Online Event Checklist",
      thumbnail: "/img/resources/organizer/suaw-stock-image-writer-notepad-2.png",
      route: "/resources/organizer/online-event-checklist",
      category: "Organizer",
      subcategory: "Hosting Events",
      preview: "Checklist for planning online writing sessions, from technical preparation to participant engagement strategies."
    },
    {
      id: "PromotingYourEvent",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Promoting Your Event",
      thumbnail: "/img/resources/organizer/suaw-stock-image-writer-1.png",
      route: "/resources/organizer/promoting-your-event",
      category: "Organizer",
      subcategory: "Hosting Events",
      preview: "Marketing strategies to increase event visibility, attract participants, and build a thriving writing community."
    },
    {
      id: "TroubleshootingAtEvents",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Troubleshooting at Events",
      thumbnail: "/img/resources/organizer/suaw-stock-image-writing-session.png",
      route: "/resources/organizer/troubleshooting-at-events",
      category: "Organizer",
      subcategory: "Hosting Events",
      preview: "Solutions and contingency plans for handling common challenges and unexpected situations during writing sessions."
    },
    {
      id: "AcademicOrganizers",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Academic Organizers",
      thumbnail: "/img/resources/organizer/suaw-stock-image-bookmark-pen.png",
      route: "/resources/organizer/academic-organizers",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Guide for organizing writing events in academic settings, addressing unique needs of faculty, students, and researchers."
    },
  ];
};

export function generalResources() {
  return [
    // {
    //   id: "About",
    //   heading: "About",
    //   thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
    //   route: "/about/suaw",
    //   category: "General",
    //   subcategory: "About Us",
    //   preview: "Learn about our mission, history, and approach to fostering creativity and productivity in writing sessions."
    // },
    // {
    //   id: "OurOrganization",
    //   heading: "Our Organization",
    //   thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
    //   route: "/resources/our-organization",
    //   category: "General",
    //   subcategory: "About Us",
    //   preview: "Explore our organization's structure, values, and the dedicated team supporting writers and writing communities."
    // },
    // {
    //   id: "Method",
    //   heading: "Method",
    //   thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
    //   route: "/about/method",
    //   category: "General",
    //   subcategory: "About Us",
    //   preview: "Learn about our proven writing method that combines focused sessions with community support for better productivity."
    // },
    // {
    //   id: "Impact",
    //   heading: "Impact",
    //   thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
    //   route: "/about/impact",
    //   category: "General",
    //   subcategory: "About Us",
    //   preview: "See how our writing approach transforms writers' lives and communities through success stories and testimonials."
    // },
    // {
    //   id: "Faq",
    //   heading: "FAQ",
    //   thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
    //   route: "/about/faq",
    //   category: "General",
    //   subcategory: "About Us",
    //   preview: "Find answers about event participation, membership benefits, and ways to engage with our vibrant writing community."
    // },
    {
      id: "GetInvolved",
      heading: "Get Involved",
      thumbnail: "/img/resources/involved-hero.png",
      route: "/about/get-involved",
      category: "General",
      subcategory: "Get Started",
      preview: "Discover ways to join our community, like attending writing sessions, organizing them, and more."
    },
    {
      id: "CodeOfConduct",
      heading: "Code of Conduct",
      thumbnail: "/img/resources/code-of-conduct-hero.png",
      route: "/resources/code-of-conduct",
      category: "General",
      subcategory: "Policies & Downloads",
      preview: "Guidelines for maintaining a respectful writing environment, outlining community expectations and shared responsibilities."
    },
    {
      id: "BecomeAnOrganizer",
      heading: "Become an Organizer",
      thumbnail: "/img/resources/become-an-organizer-hero.png",
      route: "/resources/become-an-organizer",
      category: "General",
      subcategory: "Get Started",
      preview: "Make an impact by becoming an organizer, leading writing sessions, and fostering a supportive community of writers."
    },
    {
      id: "PrivacyPolicy",
      heading: "Privacy Policy",
      thumbnail: "/img/resources/about-hero.png",
      route: "/resources/privacy-policy",
      category: "General",
      subcategory: "Policies & Downloads",
      preview: "Overview of how we protect and manage your personal data, ensuring transparency and compliance with privacy standards."
    },
    {
      id: "TermsOfService",
      heading: "Terms of Service",
      thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
      route: "/resources/terms-of-service",
      category: "General",
      subcategory: "Policies & Downloads",
      preview: "Terms and conditions governing platform usage, detailing user rights, responsibilities, and community participation."
    },
    {
      id: "Legal",
      heading: "Legal",
      thumbnail: "/img/resources/impact-hero.png",
      route: "/resources/legal",
      category: "General",
      subcategory: "Policies & Downloads",
      preview: "Important legal information about platform usage, including service terms, compliance requirements, and key disclaimers."
    },
    {
      id: "Downloads",
      heading: "Downloads",
      thumbnail: "/img/resources/organizer/suaw-stock-image-table-tent.png",
      route: "/resources/downloads",
      category: "General",
      subcategory: "Policies & Downloads",
      preview: "Access our collection of writing resources, templates, and materials to enhance your sessions and organizational activities."
    },
    {
      id: "Contact",
      heading: "Contact",
      thumbnail: "/img/resources/organizer/suaw-stock-image-bookmark.png",
      route: "/resources/contact",
      category: "General",
      subcategory: "Get Started",
      preview: "Connect with our team for support, inquiries, or collaboration opportunities to enhance your writing journey."
    },
  ];
}
